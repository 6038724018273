import { trpc } from '../../util/trpc.ts'
import { useState } from 'react'
import TrpcWrapper from '../context/TrpcReact.context.tsx'
import { formatNumber } from '../../util/format.util.ts'
import { Route } from '../../config/route.config.ts'
import { BlueCard } from '../shared/Card.tsx'

export default function () {
  return (
    <TrpcWrapper>
      <Content />
    </TrpcWrapper>
  )
}

const Content = () => {
  const [type, setType] = useState<'week' | 'month' | 'quarter'>('month')
  const { data, refetch } = trpc.ghilo.top.useQuery({ type })

  return (
    <>
      <div
        style={{
          textAlign: 'justify',
          padding: '5px 0',
        }}
        className="text-sm mt-5 text-[#2e2e2e]"
      >
        Bảng Top là nơi thống kê kết quả chơi loto của các thành viên RBK. Bảng được chia thành các
        mục Tuần, Tháng, Quý. Mỗi mục liệt kê theo thứ tự từ cao xuống
        thấp của 50 thành viên giành được nhiều k nhất trong khoảng thời gian tương ứng.
      </div>

      <div
        style={{
          color: '#e53838',
          padding: '3px 0',
          textAlign: 'left',
        }}
        className="mt-5 mb-2 text-sm"
      >
        * Thành viên bị cấm chat sẽ không được hiển thị trong bảng top
      </div>

      <BlueCard title="bảng top cao thủ" isFull={true}>
        <div className="font-semibold text-sm">
          <div className="bg-[#FFDC45] p-2">
            <a
              className={`${type === 'week' ? 'text-[#b50404]' : 'text-[#333333]'} mr-1 cursor-pointer`}
              onClick={() => {
                if (type == 'week') return
                setType('week')
                refetch()
              }}
            >
              Theo tuần
            </a>
            |{' '}
            <a
              className={`${type === 'month' ? 'text-[#b50404]' : 'text-[#333333]'} mr-1 cursor-pointer`}
              onClick={() => {
                if (type == 'month') return
                setType('month')
                refetch()
              }}
            >
              Theo tháng
            </a>
            |{' '}
            <a
              className={`${type === 'quarter' ? 'text-[#b50404]' : 'text-[#333333]'} mr-1 cursor-pointer`}
              onClick={() => {
                if (type == 'quarter') return
                setType('quarter')
                refetch()
              }}
            >
              Theo quý
            </a>
          </div>
        </div>

        <div
          id="toplistholder"
          style={{
            height: '230px',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <table
            className="toptbl overflow-y-scroll max-h-[500px]"
            cellSpacing="1"
            cellPadding="3"
            style={{ width: '100%' }}
          >
            <tbody>
              {data?.map((it, index) => (
                <tr key={index}>
                  <td className="bg-[#eaeff6] w-[30px] text-center">{index + 1}</td>
                  <td className="bg-[#d7e9fd] text-[#34548F] font-semibold pl-2">
                    <a href={Route.chat(it.user_id ?? '')}>{it.name}</a>
                  </td>
                  <td className="balance bg-[#FFE4C1] text-[#008000] w-[80px] pl-2">
                    {formatNumber((it.pnl ?? 0) * 1000, '')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </BlueCard>
    </>
  )
}
